import React, { useEffect, useState } from "react"
import MainLayout from "../components/layouts/main-layout"
import loadable from "@loadable/component"
import { graphql, Link } from "gatsby"
import HomeHero from "../components/hero/hero"
import pMinDelay from "p-min-delay"

import Seo from "../components/seo"
import { LazyLoadComponent } from "react-lazy-load-image-component"

// const HomeHero = loadable(() =>
//   pMinDelay(import("../components/hero/hero"), 1000)
// )
const PartnerSwiper = loadable(() =>
  pMinDelay(import("../components/swiper/swiper-partner.js"), 500)
)
const VideoSlider = loadable(() =>
  pMinDelay(import("../components/video-slider/video-slider.js"), 500)
)
const ItConsulting = loadable(() =>
  pMinDelay(import("../components/it-consulting"), 500)
)

const Testimonials2 = loadable(() =>
  pMinDelay(import("../components/swiper/testimonial-swiper"), 500)
)
const QuandaryValues = loadable(() =>
  pMinDelay(import("../components/new-quandary/quandary-services"), 500)
)
const UnlockEnterprise = loadable(() =>
  pMinDelay(import("../components/unlock-enterprise"), 500)
)
const Cases = loadable(() => pMinDelay(import("../components/cases"), 500))
//const Hero = loadable(() => import("../components/hero/hero"))
const CaseStudySection = loadable(() =>
  pMinDelay(import("../components/header/header-casestudy-section"), 500)
)

const CaseComponent = ({ dataSrc }) => {
  return (
    <section id="case-studies-container">
      <div id="case-studies" className="bg-indigo py-5">
        <div className="container">
          <div className="intro text-center">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <p
                  className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                  style={{ fontFamily: "Futura,sans-serif" }}
                >
                  Case Studies
                </p>
                <h2 className=" my-2 text-white">
                  We Save Clients 1000s of Hours. Every Year.
                </h2>
                {/* <p className="text-white">
                  We helped a national healthcare provider save over 9,000 hours
                  in weekly labor costs by developing a single application...
                </p> */}
              </div>
            </div>
          </div>
        </div>
        {dataSrc && <Cases allSanityCaseStudy={dataSrc} />}
        <div className="text-center">
          <Link
            className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
            to="/case-studies/"
          >
            More Case Studies
          </Link>
        </div>
      </div>
    </section>
  )
}

const Homepage = props => {
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    function f2(ev) {
      ev.preventDefault()
    }

    // A function that invokes a callback when the page has unsaved changes.
    // window.addEventListener("beforeunload", f2)

    // A function that invokes a callback when the page's unsaved changes are resolved.
    // window.removeEventListener("beforeunload", f2)

    // document.body.addEventListener("click", f2, {
    //   capture: false,
    //   passive: true,
    // })
    // document.body.addEventListener("touchstart", f2, {
    //   passive: true,
    //   capture: false,
    // })
  }, [])
  const { data } = props

  return (
    <MainLayout location={props.location} dataSrc={data.allSanityCaseStudy}>
      {/*<Hero />*/}
      <Seo
        title="Quickbase and Workato Elite Service Partners | Quandary"
        description="As a leading IT consulting firm, we build customer solutions that improve workflows and business outcomes (in weeks) using low-code platforms."
      />

      <HomeHero />

      <CaseComponent dataSrc={data.allSanityCaseStudy} />

      <PartnerSwiper />
      <VideoSlider />

      <div id="it-consult" className="it-consult-scroll" />

      <ItConsulting />

      <QuandaryValues />

      <UnlockEnterprise />
      <CaseStudySection />
      <Testimonials2 />
    </MainLayout>
  )
}

export default Homepage

export const indexQuery = graphql`
  query indexQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                layout: CONSTRAINED
                width: 580
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                layout: CONSTRAINED
                width: 580
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
